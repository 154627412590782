// elatnaker

import { createApp } from 'vue'
import i18n from './i18n'
import { createVuestic } from 'vuestic-ui'
import { createGtm } from '@gtm-support/vue-gtm'
import VueCountdown from '@chenfengyuan/vue-countdown'
import stores from './stores'
import router from './router'
import vuesticGlobalConfig from './services/vuestic-ui/global-config'
import PDFObjectPlugin from 'pdfobject-vue'
import VueFroala from 'vue-froala-wysiwyg'
import App from './App.vue'

import 'animate.css'
import 'swiper/swiper-bundle.css'
//Import Froala Editor 
import 'froala-editor/js/plugins.pkgd.min.js';
//Import third party plugins
import 'froala-editor/js/third_party/embedly.min';
import 'froala-editor/js/third_party/font_awesome.min';
import 'froala-editor/js/third_party/spell_checker.min';
import 'froala-editor/js/third_party/image_tui.min';
// Import Froala Editor css files.
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';
// import '~vue-wysiwyg/dist/vueWysiwyg.css'

// import 'aos'

import './permission'

const app = createApp(App)

app.use(stores)
app.use(router)
app.use(i18n)
app.use(PDFObjectPlugin)
app.use(VueFroala)
app.use(createVuestic({ config: vuesticGlobalConfig }))
app.component(VueCountdown.name, VueCountdown)

if (import.meta.env.VITE_APP_GTM_ENABLED) {
  app.use(
    createGtm({
      id: import.meta.env.VITE_APP_GTM_KEY,
      debug: false,
      vueRouter: router,
    }),
  )
}

app.mount('#app')
