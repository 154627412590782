import router, { getPaths } from './router'
// import NProgress from 'nprogre' // progress bar
import { getStorage, getToken, removeToken } from './utils/auth' // get token from cookie
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getCookie, setCookie } from 'typescript-cookie'
import { useToast } from 'vuestic-ui/web-components'
// import getPageTitle from '@/utils/get-page-title'

const { notify } = useToast()

NProgress.configure({ showSpinner: false }) // NProgress Configurations

const whiteList = ['/auth/masuk', '/auth/daftar'] // no redirect whitelist
// console.log(getPaths('admin'))
// const whiteListBeforeLogin = getPaths('admin')

// console.log(getPaths('admin'))

router.beforeEach(async (to, from, next) => {
  // to and from are both route objects. must call `next`.
  NProgress.start()

  // set page title
  // document.title = getPageTitle(to.meta.title)

  // determine whether the user has logged in
  const hasToken = getToken()
  // const hasToken = jsCookie.get('roleLogin')

  const hasProfileNotComplete = getCookie('complete_profile')
  const hasProfileNotCompleteFromLogin = getCookie('complete_profile_from_login')
  if (hasProfileNotComplete || hasProfileNotCompleteFromLogin) {
    if (to.path === '/auth/update-profile') {
      next()
    } else {
      next('/auth/update-profile')
    }
    NProgress.done()
    return
  }

  const hasOtpSended = getCookie('send-otp')
  if (hasOtpSended) {
    if (to.path === '/auth/otp') {
      next()
    } else {
      next('/auth/otp')
    }
    NProgress.done()
    return
  }

  const hasResetPass = getCookie('reset-pass')
  if (hasResetPass) {
    console.log(hasResetPass)
    if (to.path === '/auth/reset-password') {
      next()
    } else {
      next('/auth/reset-password')
    }
    NProgress.done()
    return
  }

  if (hasToken) {
    const newWhiteList = [...whiteList, '/auth/otp', '/auth/update-profile', '/auth/reset-password']
    if (newWhiteList.includes(to.path)) {
      // if is logged in, redirect to the home page
      next({ path: '/' })
      NProgress.done() // hack: https://github.com/PanJiaChen/vue-element-admin/pull/2939
    } else {
      try {
        // store.dispatch('permission/staticRoutes') // add routes

        next()
      } catch (error) {
        // remove token and go to login page to re-login
        removeToken()
        notify({ message: 'Has Error', color: 'danger' })
        console.log(error)
        next(`/auth/masuk`)
        NProgress.done()
      }
    }
    // next()
  } else {
    /* has no token*/
    const hasOtpSended = getCookie('send-otp')
    if (hasOtpSended) {
      if (to.path === '/auth/otp') {
        next()
      } else {
        next('/auth/otp')
      }
    } else {
      const newWhiteList = [...whiteList, ...getPaths('landing_page')]
      if (newWhiteList.indexOf(to.path) !== -1) {
        // in the free login whitelist, go directly
        next()
      } else {
        // other pages that do not have permission to access are redirected to the login page.
        next(`/`)
        NProgress.done()
      }
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
